import React from 'react';

const ArrowSvg = color => {
  return (
    <svg
      style={{ color }}
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Arrow"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-4.57764e-05 7.875L12.6487 7.875L7.75751 12.7663L9 14L16 7L9 0L7.76625 1.23375L12.6487 6.125L-4.57764e-05 6.125V7.875Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowSvg;
