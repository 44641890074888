import React, { useEffect, useState } from 'react';
import Link from 'next/link'; // You may need to import the Link component from Next.js
import styles from '../../styles/LandingPage.module.scss'; // Import your styles here
import ArrowSvg from './ArrowSvg';
import { ARCHIVED_PAGE_BASE_URL } from './Layout';

const Header = () => {
  const [isSticky, setIsSticky] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('scroll', toggleSticky);
    return () => {
      window.removeEventListener('scroll', toggleSticky);
    };
  }, []);

  const toggleSticky = () => {
    const sticky = window.scrollY >= 20;
    setIsSticky(sticky);
  };

  return (
    <div id="header" className={`${styles.header} ${isSticky ? styles.sticky : styles.transparentBg}`}>
      <div className={styles.headerLogo}>
        <a
          href="https://www.mckinsey.com/capabilities/mckinsey-digital/how-we-help-clients"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/mckinsey-logo.svg" width="80px" alt="McKinsey Digital" />
        </a>
      </div>
      <div className={styles.headerLinkContainer}>
        <div className={styles.headerLink}>
          <a href={`/${ARCHIVED_PAGE_BASE_URL}`} target="_blank" rel="noreferrer">
            Visit the old Incepteam.com {ArrowSvg('white')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
