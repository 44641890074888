/* eslint-disable prettier/prettier */
import React, { useRef } from 'react';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { heapEnvironmentId } from '../lib/components/Defaults';
import LinkedInIcon from '../lib/icons/LinkedInIcon';

import ArrowSvg from '../lib/components/ArrowSvg';
import Header from '../lib/components/LandingHeader';

import styles from '../styles/LandingPage.module.scss';
import CtaButton from '../lib/components/CtaButton';

export default function NewLandingPage() {
  const scrollToContainer = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    let resizeTimer;
    window.addEventListener('resize', () => {
      document.body.classList.add(`${styles.resizeAnimationStopper}`);
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        document.body.classList.remove(`${styles.resizeAnimationStopper}`);
      }, 400);
    });
  }, []);

  const handleDeclineCookie = () => () => {
    //remove heap cookies
    Cookies.remove('heap');
    Cookies.remove(`_hp2_id.${heapEnvironmentId}`);
    Cookies.remove(`_hp2_ses_props.${heapEnvironmentId}`);
    Cookies.remove(`_hp2_props.${heapEnvironmentId}`);
  };

  const smoothScrollTo = () => () => {
    if (scrollToContainer) {
      scrollToContainer.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      });
    }
  };

  return (
    <>
      <CookieConsent
        enableDeclineButton
        onDecline={handleDeclineCookie()}
        location={'none'}
        containerClasses={styles.cookieBanner}
        buttonClasses={styles.cookieButton}
        buttonText={'Accept cookies'}
        declineButtonClasses={styles.cookieButton}
        declineButtonText={'Decline cookies'}
        flipButtons={true}
        contentClasses={styles.cookieContent}
        overlayClasses={styles.cookieOverlay}
        overlay={true}
      >
        Our website uses cookies to analyze how the site is used and to ensure the experience is consistent between
        visits. {/* TODO update cookie policy website link */}
        <a href="/Cookie_policy_website.pdf" className={styles.cookiePolicyLink} target="_blank">
          Cookie policy
        </a>
      </CookieConsent>
      <div className={styles.landing}>
        <Header />
        <div className={styles.container}>
          <div className={`${styles.topImage} ${styles.image}`}>
            <img src="/logo-horizontal.svg" alt="IncepTech Ltd." className={styles.inceptechLogo} />
          </div>
          <div className={`${styles.bottomImage} ${styles.image}`}>
            <img src="/mckinsey-logo.svg" alt="McKinsey Digital" className={styles.mckLogo} />
          </div>
          <div className={styles.subContainer}>
            <div className={styles.iframeContainer}>
              <iframe
                className={styles.iframe}
                src="https://my.spline.design/clonercubebinarycopy-828ce19c66739f57b5471d22a9afb782"
                width="500px"
                height="500px"
              ></iframe>
            </div>
            <div className={styles.textContainer}>
              <div className={styles.text}>
                IncepTech became
                McKinsey Digital’s Budapest Studio
              </div>
              <div className={styles.subText}>
                Over a year has passed since we joined McKinsey & Company, and the results are resounding. 
                Together, we’ve established a groundbreaking Digital Studio in Budapest, steadfastly advancing our
                mission to empower organizations to excel in the era of Digital and AI.
                <br />
                <a
                  className={styles.readMoreLink}
                  onClick={smoothScrollTo()}
                >
                  Follow our journey  {ArrowSvg('#FFFFFF')}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div id="scrollingPoint" ref={scrollToContainer} className={styles.content}>
          <div className={styles.contentTop}>
            <h3 className={styles.title}>Stay with us on our exciting journey</h3>
            <p className={styles.supportingText}>
              as we are building the most renowned tech community in Central Europe
            </p>
          </div>
          <div className={styles.cardContainer}>
            <div className={styles.card} style={{ borderTop: '4px solid #051C2C' }}>
              <div className={styles.cardTop}>
                <div className={styles.cardTitle}>Follow us on LinkedIn</div>
                <p className={styles.cardSubtext}>
                  Get insights into the life of the Budapest Studio and our regular community events.
                </p>
              </div>
              <div className={styles.link}>
                <a href="https://www.linkedin.com/company/mckinsey-digital-budapest" target="_blank" rel="noreferrer">
                  LinkedIn {ArrowSvg('#2251FF')}
                </a>
              </div>
            </div>
            <div className={styles.card} style={{ borderTop: '4px solid #00A9F4' }}>
              <div className={styles.cardTop}>
                <div className={styles.cardTitle}>Embrace the Future with McKinsey Digital</div>
                <p className={styles.cardSubtext}>Explore how we drive growth in the digital age.</p>
              </div>
              <div className={styles.link}>
                <a
                  href="https://www.mckinsey.com/capabilities/mckinsey-digital/how-we-help-clients"
                  target="_blank"
                  rel="noreferrer"
                >
                  Meet McKinsey Digital {ArrowSvg('#2251FF')}
                </a>
              </div>
            </div>
            <div className={styles.card} style={{ borderTop: '4px solid #2251FF' }}>
              <div className={styles.cardTop}>
                <div className={styles.cardTitle}>How can we help you?</div>
                <p className={styles.cardSubtext}>
                  We are here to help! Drop us a line! Our team is excited to assist you.
                </p>
              </div>
              <div className={styles.link}>
                <CtaButton text={'Contact us'} href={'mailto:bds-join@mckinsey.com'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}