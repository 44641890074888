import React from 'react';
import styles from '../../styles/CtaButton.module.scss';

interface CtaButtonProps {
  text: string;
  href: string;
}

const CtaButton = (props: CtaButtonProps) => {
  const handleClick = () => () => {
    window.location.href = `${props.href}`;
  };

  return (
    <button className={styles.ctaButton} onClick={handleClick()}>
      {props.text}
    </button>
  );
};

export default CtaButton;
